@font-face {
    font-family: 'FreshSans_Bold';
    src:    url('./assets/fonts/woolworths/FreshSans-Bold_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-Bold_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-Bold_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-Bold_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-Bold_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-Bold_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_Bold_Italic';
    src:    url('./assets/fonts/woolworths/FreshSans-BoldItalic_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-BoldItalic_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-BoldItalic_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-BoldItalic_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-BoldItalic_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-BoldItalic_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_Italic';
    src:    url('./assets/fonts/woolworths/FreshSans-Italic_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-Italic_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-Italic_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-Italic_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-Italic_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-Italic_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_Light';
    src:    url('./assets/fonts/woolworths/FreshSans-Light_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-Light_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-Light_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-Light_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-Light_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-Light_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_LightItalic';
    src:    url('./assets/fonts/woolworths/FreshSans-LightItalic_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-LightItalic_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-LightItalic_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-LightItalic_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-LightItalic_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-LightItalic_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_Medium';
    src:    url('./assets/fonts/woolworths/FreshSans-Medium_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-Medium_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-Medium_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-Medium_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-Medium_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-Medium_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_MediumItalic';
    src:    url('./assets/fonts/woolworths/FreshSans-MediumItalic_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-MediumItalic_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-MediumItalic_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-MediumItalic_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-MediumItalic_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-MediumItalic_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreshSans_Regular';
    src:    url('./assets/fonts/woolworths/FreshSans-Regular_1_350_web.eot');
    src:    url('./assets/fonts/woolworths/FreshSans-Regular_1_350_web.eot?#iefix') format('embedded-opentype'),
            url('./assets/fonts/woolworths/FreshSans-Regular_1_350_web.woff') format('woff'),
            url('./assets/fonts/woolworths/FreshSans-Regular_1_350_web.woff2') format('woff2'),
            url('./assets/fonts/woolworths/FreshSans-Regular_1_350_web.ttf') format('truetype'),
            url('./assets/fonts/woolworths/FreshSans-Regular_1_350_web.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:    url('./assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}