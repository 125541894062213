@use "@angular/material" as mat;
@import "src/fonts.scss"; // Import the custom fonts
@include mat.core();

$defaultBackground: #f5f5f5;

$woolworths-green: (
  50: #e6f4e8,
  100: #c0e0c6,
  200: #98ccb0,
  300: #70b899,
  400: #4aa579,
  500: #339655,
  600: #2e864d,
  700: #287445,
  800: #22643d,
  900: #1c5335,
  A100: #a7e5b2,
  A200: #74cf89,
  A400: #339c5f,
  A700: #267a4b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
  )
);

$woolworths-yellow: (
  50: #fffbe6,
  100: #fff5c0,
  200: #ffee98,
  300: #ffe870,
  400: #ffdf4b,
  500: #ffd933,
  600: #e6c32e,
  700: #ccad28,
  800: #b39723,
  900: #99811e,
  A100: #fff5a7,
  A200: #ffe574,
  A400: #ffd433,
  A700: #cca726,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
  )
);

// Apply custom fonts
html, body {
  font-family: 'FreshSans_Regular', Arial, sans-serif;
  height: 100%;
  margin: 0;
  background: $defaultBackground;
}

h1, h2, h4 {
  font-family: 'FreshSans_Bold', Arial, sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 38px;
  line-height: 42px;
}

h2 {
  font-size: 28px;
  line-height: 32px;
}

h4 {
  font-size: 24px;
  line-height: 28px;
}

$woolworths-primary: mat.define-palette($woolworths-green);
$woolworths-accent: mat.define-palette($woolworths-yellow);
$woolworths-warn: mat.define-palette(mat.$red-palette);
$woolworths-theme: mat.define-light-theme(
    (
      color: (
        primary: $woolworths-primary,
        accent: $woolworths-accent,
        warn: $woolworths-warn,
      )
    )
);

@include mat.all-component-themes($woolworths-theme);

$primaryColor: mat.get-color-from-palette($woolworths-primary);
$accentColor: mat.get-color-from-palette($woolworths-accent);
$warnColor: mat.get-color-from-palette($woolworths-warn);

.primaryColor {
  color: $primaryColor;
}

.accentColor {
  color: $accentColor;
}

.warnColor {
  color: $warnColor;
}

.link {
  color: inherit;
  font-weight: 500;
  &:hover {
    color: $primaryColor;
  }
}

// Additional styling
.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined' !important;
}

.mat-mdc-snack-bar-container {
  &.error {
    --mdc-snackbar-container-color: #f44336;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

mat-tab-group {
  mat-tab-header {
    margin-bottom: 20px;
    background-color: $defaultBackground;
  }

  mat-tab-body-wrapper {
    margin-top: 20px;
  }
}

// Rich text styles
b {
  font-weight: bold;
}

.contentfulRichText {
  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
